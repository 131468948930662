@use 'src/css/breakpoint.scss' as *;

.indexSection {
  padding: 48px 0 70px;
  position: relative;
  background: var(--main-dark-color);

  @include maxMediaWidth(lg) {
    padding: 15px 0 55px;

    &.hideSubtitleOnTablet .subTitle {
      display: none;
    }
  }

  @include maxMediaWidth(sm) {
    padding: 5px 16px 35px;
  }
}

.container {
  position: relative;
  z-index: 2;

  @include maxMediaWidth(lg) {
    display: flex;
    justify-content: center;
  }

  @include maxMediaWidth(sm) {
    padding: 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr var(--calculator-max-width);
  grid-template-rows: auto 1fr;
  grid-column-gap: 50px;

  @include maxMediaWidth(lg) {
    grid-template-columns: 1fr;
    width: min-content;
    justify-items: center;
  }

  @include maxMediaWidth(sm) {
    width: 100%;
    max-width: unset;
  }
}

.wrapTitles {
  padding-top: 15px;

  @include maxMediaWidth(lg) {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include maxMediaWidth(sm) {
    padding: 0 45px 0 15px;
    align-items: flex-start;
  }
}

.videoButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1;
  grid-row: 2;

  border: none;
  border-radius: 8px;
  width: 100%;
  max-width: 540px;
  padding: 15px 35px;
  margin-bottom: 90px;
  background: linear-gradient(124deg, #0e1f41 0%, #243870 51.3%, #0b515b 86.21%);
  cursor: pointer;

  &:hover {
    background: linear-gradient(124deg, #0e1f41 0%, #243870 51.3%, #2c529c 86.21%);
  }

  @include maxMediaWidth(lg) {
    grid-row: 3;
    margin: 48px 0 40px;
    max-width: var(--calculator-max-width);
  }

  @include maxMediaWidth(sm) {
    padding: 15px 20px;
  }

  .videoContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--main-title-color);

    span:nth-child(1) {
      font-size: 1.8rem;
      font-weight: var(--weight-bold);
      line-height: 2.6rem;

      @include maxMediaWidth(sm) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    span:nth-child(2) {
      font-size: 1.4rem;
      font-weight: var(--weight-normal);
      line-height: 1.8rem;

      @include maxMediaWidth(sm) {
        font-size: 1.2rem;
        line-height: 2.4rem;
      }
    }
  }

  .playIcon {
    width: 60px;
    height: 60px;

    @include maxMediaWidth(lg) {
      width: 48px;
      height: 48px;
    }

    @include maxMediaWidth(sm) {
      width: 40px;
      height: 40px;
    }
  }
}

.childWrap {
  display: grid;
  place-items: center;
  grid-column: 2;
  grid-row: span 2;
  width: var(--calculator-max-width);
  position: relative;

  @include maxMediaWidth(lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: 1;
    grid-row: 2;
  }

  @include maxMediaWidth(sm) {
    width: 100%;
  }
}

.title {
  margin: 0 0 25px;
  max-width: 470px;
  font-size: 5.5rem;
  font-weight: var(--weight-medium);
  line-height: 6.5rem;
  color: var(--main-title-color);
  word-break: keep-all;

  @include maxMediaWidth(lg) {
    line-height: 50px;
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 32px;
    max-width: 620px;
  }

  @include maxMediaWidth(sm) {
    max-width: unset;
    text-align: unset;
    margin-bottom: 22px;
    font-size: 2.4rem;
    line-height: 34px;
    font-weight: 500;
  }
}

.subTitle {
  margin: 0;
  font-size: 1.8rem;
  font-weight: var(--weight-normal);
  line-height: 2.6rem;
  color: var(--main-title-color);
  margin-bottom: 55px;
  word-break: keep-all;

  @include maxMediaWidth(lg) {
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 45px;
  }

  @include maxMediaWidth(sm) {
    text-align: unset;
    font-size: 1.35rem;
    line-height: 20px;
    margin-bottom: 30px;
  }
}

.videoButton ~ .externalLinks {
  grid-row: 3;

  @include maxMediaWidth(lg) {
    grid-row: 4;
    margin-top: 0;
  }
}

.externalLinks {
  display: flex;
  align-items: center;
  grid-column: 1;
  grid-row: 2;
  width: 100%;

  @include maxMediaWidth(lg) {
    margin: 48px auto 0;
    grid-row: 3;
    align-items: center;
  }
}

.backOverlay {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & video {
    width: 50vw;
    z-index: 7;

    @include maxMediaWidth(sm) {
      width: 90vw;
    }
  }
  & > svg {
    z-index: 8;
    position: fixed;
    top: 120px;
    right: 40px;
    cursor: pointer;
    @media screen and (max-height: 468px) {
      filter: invert(0%) sepia(40%) saturate(15%) hue-rotate(10deg) brightness(15%) contrast(103%);
      top: 60px;
      right: 50px;
    }
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  animation: init 0.5s forwards;

  background: hsl(0deg 0% 0% / 70%);
}

@keyframes init {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
